.flashcard-mode-component {
  .count {
    font-weight: 500;
    font-size: 1rem;
  }

  .btn-control {
    width: 48px;
    height: 48px;
  }

  button {
    &.on {
      color: #6b7fff !important;
      border-color: #6b7fff !important;
      background: #ffffff;
    }
  }

  .ant-spin-nested-loading {
    min-height: 400px;
  }

  .flashcard-carousel {
    min-height: 300px;
  }

  .carousel-container {
    position: relative;

    .notification {
      .ant-ribbon-corner {
        display: none;
      }
    }
  }
}
