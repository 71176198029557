$btnTextColor: #586380;
$btnHoverBg: #f6f7fb;
$btnActiveBg: #edeff4;
$btnHoverBorder: #0000;

$btnYellowBg: #ffcd1f;
$btnYellowHoverBg: #ffdc62;
$btnYellowActiveBg: #ffedab;

.ant-btn {
  border: 0.125rem solid #0000;
  padding: 0.375rem 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.btn-transparent {
    color: $btnTextColor;

    &:hover {
      color: $btnTextColor !important;
      background: $btnHoverBg !important;
      border: 0.125rem solid $btnHoverBorder;
    }

    &:active {
      color: $btnTextColor !important;
      background: $btnActiveBg !important;
      border: 0.125rem solid $btnHoverBorder;
    }
  }

  &.btn-yellow {
    background: $btnYellowBg;

    &:hover {
      background: $btnYellowHoverBg !important;
    }

    &:active {
      background: $btnYellowActiveBg !important;
    }
  }

  &.btn-block {
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
  }

  &.btn-outline {
    padding: 0.625rem 1.375rem;
    border-color: #d9dde8;
    background: #0000;
    height: unset;

    &:not(:disabled) {
      color: #586380 !important;
    }

    &:hover {
      background: #edeff4 !important;
    }

    &:active {
      background: #d9dde8 !important;
    }
  }

  &.btn-primary-transparent {
    height: unset;
    color: #4255ff !important;
    padding: 0.625rem 1.375rem;

    &:hover {
      background: #edefff !important;
    }

    &:active {
      background: #dbdfff !important;
    }
  }

  &.btn-danger {
    background: #b00020 !important;

    &:hover {
      background: #da4543 !important;
    }

    &:active {
      background: #ff7873 !important;
    }
  }

  &.btn-border-gray:not(:disabled) {
    border: 0.125rem solid #d9dde8;
    color: #586380;
  }
}
