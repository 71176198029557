.selected-mode {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 0.5rem;
  font-size: 1rem;

  &:hover {
    background-color: #f6f7fb;
  }
}
