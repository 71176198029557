.quiz-card {
  padding: 0.75rem 1.25rem;
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem #00000014;
  overflow: hidden;
  border-bottom: 0.25rem solid #fff0;
  height: 170px;
  cursor: pointer;

  .name {
    font-weight: 500;
    font-size: 1rem;
  }

  &:hover {
    border-bottom-color: #a8b1ff;
  }

  .creator {
    padding: 8px;
    border: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f6f7fb;
    }
  }
}
