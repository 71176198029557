.ant-layout {
  &:has(.no-layout) {
    & > .header,
    footer {
      display: none;
    }

    .main-content {
      margin: 0px;
      padding: 0px;
      max-width: unset;
      min-width: unset;

      &:not(:has(.scroll)) {
        height: 100vh;
      }

      &:has(.scroll) {
        min-height: 100vh;
      }

      .no-layout-mode {
        flex: 1;
        .flashcard-mode-component {
          margin: 1.5rem 1rem 0;

          .flip-card,
          .ant-spin-nested-loading {
            height: calc(100vh - 64px - 1.5rem * 2 - 48px);
            min-height: none;
          }

          .btn-fullscreen {
            display: none;
          }

          .ant-progress {
            display: none;
          }
        }

        .header {
          background-color: white;
          padding: 8px;
          height: 64px;

          .title,
          .mobile-title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5;
            align-items: center;
          }

          .mobile-title {
            display: none;
          }
        }

        .mode-content {
          min-height: 250px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        @media (max-width: 38.75rem) {
          .header {
            height: 115px;

            .title {
              display: none;
            }
            .mobile-title {
              display: flex;
            }
          }

          .mode-content {
            margin-top: 115px;
          }

          .flashcard-mode-component {
            .flip-card,
            .ant-spin-nested-loading {
              height: calc(100vh - 115px - 1.5rem * 2 - 48px);
              min-height: none;
            }
          }
        }
      }
    }
  }
}
