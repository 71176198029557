.ant-select {
  &.search-select {
    &:hover {
      .ant-select-selector {
        border-color: #939bb4 !important;
      }
    }

    .ant-select-selector {
      background: #f6f7fb;
      border: 0.078125rem solid var(--defaultBorder);
      border-radius: 4.75rem;
      padding: 0 20px !important;
    }

    .ant-select-selection-search {
      input {
        padding: 0 10px !important;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: normal;
        line-height: 1.5;
        color: var(--defaultText);
      }
    }

    .ant-select-selection-placeholder {
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: normal;
      line-height: 1.5;
      color: var(--secondText);
    }
  }
}

.ant-select-dropdown {
  .ant-select-item {
    .search-bar-select-items {
      .anticon svg {
        font-size: 20px;
      }
      .ant-space-item {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5;
        color: var(--defaultText);
      }
    }
  }
}
