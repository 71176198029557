.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    padding: 0.5rem 1rem !important;

    &:has(.user-dropdown-item) {
      &:hover {
        background-color: transparent;
      }
    }

    &:has(.border-top-divider) {
      border-top: 0.0625rem solid #edeff4;
    }
  }

  .add-dropdown-item {
    display: block;

    svg {
      font-size: 1.2rem;
      color: #586380;
    }

    span {
      color: #586380;
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  .user-dropdown-item {
    border-bottom: 0.0625rem solid #edeff4;
    padding-bottom: 1rem;

    .user-name,
    .user-email {
      font-weight: 600;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
    }

    .user-email {
      font-weight: 400;
    }

    .avatar-circle {
      font-size: 1.25rem;
    }
  }
}
