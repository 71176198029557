.quiz-info-page {
  flex-grow: 1;
  padding-bottom: 2rem;

  .review-star {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  .btn-type {
    button {
      box-shadow: 0 0.0625rem 0.1875rem 0 #282e3e14;
      height: unset;
      font-size: 16px;
      min-width: 180px;
      border: 0px;

      .ant-btn-icon {
        margin-inline-end: 0.75rem;
      }
    }
  }
}

@media (max-width: 48rem) {
  .quiz-info-page .btn-type {
    button {
      font-size: 14px;
      min-width: 130px;
    }
  }
}
