.folder-page {
  flex-grow: 1;

  .title {
    margin-top: 1.5rem;

    h1 {
      margin: 0;
    }

    .anticon {
      font-size: 50px;
      color: #939bb4;
    }
  }

  .quizzes {
    font-weight: 500;
    font-size: 0.875rem;
  }

  .creator {
    cursor: pointer;
  }
}
