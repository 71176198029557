.ant-popover {
  .ant-popconfirm-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
  }

  .ant-btn-default {
    border: 0.125rem solid #d9dde8;
    color: #586380;

    &:hover,
    &:active {
      color: #6b7fff;
      border-color: #6b7fff;
      background: #ffffff;
    }
  }
}
