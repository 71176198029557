.learn-mode {
  .test-content {
    margin: 2rem 1rem 5rem 1rem;
  }

  .btn-next {
    background-color: #fff;
    bottom: 0px;
    box-shadow: 0 -0.25rem 1rem #2e385614;
    height: 5rem;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 401;
    padding: 0px 1.5rem;

    button {
      max-width: 52.5rem;
    }
  }

  @media (max-width: 64rem) {
    .btn-next button {
      max-width: 45rem;
      padding: 1.5rem;
    }

    .test-content {
      margin: 0.5rem;
    }
  }

  @media (max-width: 38.75rem) {
    .btn-next button {
      max-width: unset;
      padding: 1rem;
    }
  }
}
