.item-container {
  background-color: #f6f7fb;
  padding: 0.25rem 1rem;
  height: 3rem;
  width: 100%;
}

.item-container-placeholder {
  font-weight: 500;
  font-size: 1rem;
  color: #939bb4;
}

.item-container-value {
  font-weight: 500;
  font-size: 1rem;
  color: #282e3e !important;
}

.ant-form {
  .ant-form-item-explain-error {
    color: #ff4d4f;
  }
  .ant-form-item {
    .ant-form-item-label {
      label {
        font-weight: 500;
        &::before {
          position: absolute;
          right: 0px;
        }
      }
    }
  }

  &.base-form {
    .ant-form-item {
      .ant-form-item-label {
        label {
          font-weight: 600;
          font-size: 0.875rem;
          color: #586380;
        }
      }
      .ant-form-item-control {
        margin-bottom: 5px;
        .ant-form-item-control-input {
          margin-bottom: 5px;
          .ant-input-affix-wrapper,
          .ant-input-number-affix-wrapper {
            @extend .item-container;
            padding: 0px 1rem 0px 0px;

            &:not(.ant-input-status-error) {
              border-color: transparent;
            }

            input,
            textarea {
              @extend .item-container-value;
              padding: 0.25rem 1rem;

              &::placeholder {
                @extend .item-container-placeholder;
              }
            }
          }

          .ant-input-number-affix-wrapper {
            padding: 0px;
            display: flex;
            align-items: center;

            .ant-input-number-suffix {
              padding-right: 1rem;
            }
          }
        }

        .ant-select {
          height: 3rem;

          &:not(.ant-select-status-error) {
            border-color: transparent;
          }

          .ant-select-selector {
            @extend .item-container;
          }

          &:not(.ant-input-status-error) {
            border-color: transparent;
          }

          .ant-select-selection-item {
            @extend .item-container-value;
          }

          .ant-select-selection-placeholder {
            @extend .item-container-placeholder;
          }
        }
      }
    }
  }

  &.bg-white {
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-input-number-affix-wrapper {
      background-color: white !important;
    }
  }

  &.create-quiz-form {
    .ant-input-affix-wrapper,
    .ant-input {
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-radius: 0px;
      box-shadow: none !important;

      &::placeholder {
        color: #777;
      }
    }
  }
}
