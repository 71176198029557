.finish-learn {
  h1 {
    text-align: center;
  }

  .congratulation-image {
    display: flex;
    justify-content: center;
  }

  img {
    height: 6.4375rem !important;
    width: 10rem !important;
  }

  .firework {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .show-image {
    background: white;
    padding: 4rem 2rem 2rem 2rem;
    position: relative;
  }

  .learned-questions {
    padding: 1rem 2rem;

    &__title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      color: #586380;
      margin: 1.5rem 0;
    }
    &__row {
      padding: 1rem 1.5rem;
      background-color: white;
      border: 0.125rem solid #edeff4;
      border-radius: 1rem;
    }
    &__answer {
      border-left: 0.0625rem solid #edeff4;
    }
  }
}

@media (max-width: 38.75rem) {
  .finish-learn img {
    height: 4.8125rem !important;
    width: 7.5rem !important;
  }
}

@media (max-width: 768px) {
  .finish-learn .learned-questions {
    &__question textarea {
      font-weight: 600;
    }
  }
}
