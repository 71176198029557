.learn-card {
  box-shadow: 0 0.25rem 1rem 0 #282e3e14;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto 0;
  max-width: 52.5rem;
  min-width: 80%;
  // min-height: 29.25rem;
  padding: 1.5rem 2rem 1rem;
  transition: all 0.5s ease;

  .question {
    font-size: 20px;
    font-weight: 300;
  }

  .title {
    font-size: 1rem;
    line-height: 1.5;
    color: #586380;
    font-weight: 500;

    &.title-correct {
      color: #12815a;
    }

    &.title-wrong {
      color: #b00020;
    }
  }

  .answers-container {
    &:has(.correct, .wrong) {
      .answer {
        cursor: context-menu;
        pointer-events: none;
        border-color: #d9dde8;

        &:not(.correct, .wrong) {
          opacity: 0.5;
        }
      }
    }

    .answer {
      border-radius: 0.5rem;
      border: 0.125rem solid #edeff4;
      cursor: pointer;
      padding: 1rem;
      word-break: break-word;
      height: 100%;

      &.correct {
        border-color: #98f1d1;
      }

      &.wrong {
        border-color: #ff7873;
      }

      &:hover {
        border-color: #939bb4;
        outline: none;
      }

      .count {
        font-weight: 500;
        background-color: #edeff4;
        border-radius: 50%;
        color: #586380;
        flex: 0 0 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .content {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .dont-know {
    padding: 1rem;

    button {
      color: #586380;
    }
  }
}

@media (max-width: 64rem) {
  .learn-card {
    padding: 1rem;

    .answer {
      .count {
        display: none !important;
      }
    }
  }
}
