.test-mode {
  .mode-content {
    padding: 2rem;
  }

  .header {
    position: fixed;
    width: 100%;
    z-index: 3;
  }

  .mode-content {
    margin-top: 64px;
  }

  .test-card {
    box-shadow: 0 0.25rem 1rem 0 #282e3e14;
    background-color: #fff;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto 0;
    max-width: 52.5rem;
    width: 800px;

    // min-height: 29.25rem;
    padding: 1.5rem 2rem 1rem;
    transition: all 0.5s ease;

    &:has(.ant-form-item-has-error) {
      border: 1px solid #ff4d4f;
    }

    .question {
      font-size: 20px;
      font-weight: 300;
    }

    .title {
      font-size: 1rem;
      line-height: 1.5;
      color: #586380;
      font-weight: 500;
    }

    .answers-container {
      .ant-radio-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        .ant-radio {
          display: none;
        }

        span {
          width: 100%;
        }

        &:has(.ant-radio-checked) {
          .answer {
            border-color: #4255ff;
            background-color: #edefff;
          }
        }
      }

      .answer {
        border-radius: 0.5rem;
        border: 0.125rem solid #edeff4;
        cursor: pointer;
        padding: 1rem;
        word-break: break-word;
        height: 100%;

        &:hover {
          border-color: #939bb4;
          outline: none;
        }

        .count {
          font-weight: 500;
          background-color: #edeff4;
          border-radius: 50%;
          color: #586380;
          flex: 0 0 1.5rem;
          height: 1.5rem;
          width: 1.5rem;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .content {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }

  @media (max-width: 64rem) {
    .test-card {
      padding: 1rem;

      .answer {
        .count {
          display: none !important;
        }
      }
    }
  }
}
