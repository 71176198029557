.class-page {
  flex-grow: 1;

  .title {
    margin-top: 1.5rem;

    h1 {
      margin: 0;
    }

    .anticon {
      font-size: 50px;
      color: #939bb4;
    }
  }

  .quiz-bar {
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem #00000014;
    padding: 0.75rem 1.25rem;
    border-bottom: 0.25rem solid #fff0;
    border-radius: 0.25rem;
    cursor: pointer;
    height: 100%;

    &:hover {
      border-bottom-color: #a8b1ff;
    }

    .quiz-title {
      font-weight: 600;
      font-size: 1.25rem;
    }

    .quizzes {
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .notification {
    font-size: 1.2rem;
  }

  .creator {
    cursor: pointer;
  }
}
