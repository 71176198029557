.register-page {
  height: 100vh;

  &__bg {
    background-image: url("../../../public/images/login-wallpaper.png");
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      position: absolute;
      margin: 5%;
      color: #282e3e;
      width: 31.25rem;
      font-weight: 700;
      font-size: 2.75rem;
    }

    .ant-image {
      position: absolute;
      margin: 5%;
      bottom: 1rem;
      width: 8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 3rem;
    max-width: 40rem;
    margin: auto;

    .title {
      color: #2e3856;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 45px;
      background-image: url("../../../public/images/accents_underline.svg");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: bottom;
      background-position-x: 0px;
      margin-bottom: 2rem;
    }

    .btn-submit {
      padding: 1.125rem 1.875rem;
      height: unset;
    }
  }
}
