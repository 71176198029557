.chat-page {
  min-height: calc(100vh - 2rem * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .chat-container {
    height: calc(100vh - 2rem * 3);
  }

  .chat-main-container {
    min-height: calc(100vh - 2rem * 3);
  }

  .scrollbar-container ul {
    height: calc(100vh - 2rem * 3);

    li {
      border-bottom: solid 1px #d1dbe3;
      & > div {
        padding: 0.6em 0.8em;
      }
    }
  }

  .contact-active {
    background-color: #dfecf7;
  }
}

@media screen and (max-width: 1600px) {
  .chat-page {
    margin: 0rem 0;
  }
}
