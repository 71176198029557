.flip-card {
  background-color: transparent;
  perspective: 62.5rem;
  height: 430px;

  .feature-item {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &.active {
      svg {
        color: var(--yellow);
      }
    }

    &:hover {
      background-color: var(--defaultBorder);
    }

    svg {
      width: 18px;
      height: 18px;
      color: var(--secondText);
    }
  }

  .ant-spin-nested-loading {
    min-height: unset !important;
    height: unset !important;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.25s;
  transform-style: preserve-3d;
}

.flip-card.flip .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: white;
  padding: 1.5rem 2rem;
  box-shadow: 0 0.25rem 1rem 0 #282e3e14;
  border-radius: 0.5rem;
  & > div {
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    line-height: 2.5rem;
    overflow-y: auto;
  }
}

.flip-card-back {
  transform: rotateX(180deg);
}

@media (max-width: 64rem) {
  .flip-card-front,
  .flip-card-back {
    padding: 1.5rem 2rem;
    font-size: 20px;
  }
}

@media (max-width: 48rem) {
  .flip-card-front,
  .flip-card-back {
    padding: 1rem;
    font-size: 16px;
  }
}
