.ant-input {
  &.transparent {
    border: none;
    border-radius: 0px;
    border-bottom: 0.1rem solid var(--darkText);
    box-shadow: none;

    &:disabled {
      border-bottom: 0.1rem solid #ffffff;
      overflow-y: hidden;
      color: #282e3e;
      background-color: #ffffff;
      cursor: default;
    }

    &:focus {
      border-color: var(--yellow);
    }
  }
}
