.feature-cards {
  .ant-card {
    height: 100%;
    background-color: transparent;
    box-shadow: none;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      h1 {
        margin: 0px;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.25;
      }

      p {
        padding: 3rem 0;
        margin: 0px;
        min-height: 1.5rem;
        font-weight: 300;
        font-size: 1.25rem;
      }

      button {
        padding: 1.125rem 1.875rem;
        min-height: 4rem;
        font-weight: 600;
        font-size: 1rem;
        width: fit-content;
      }
    }
  }
}
