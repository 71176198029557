.setting {
  padding: 2rem 0px;
  margin: 0 auto;
  width: 100%;
  &__title {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: normal;
    line-height: 1.25;
    margin: 0px;
  }

  &__content {
    .container-title {
      font-weight: 500;
      font-size: 0.875rem;
      color: #586380;
      line-height: 46px;
    }

    .container-content {
      border-radius: 1rem;
      border: 0.125rem solid #edeff4;

      & > div {
        padding: 1rem;

        &:not(:first-child) {
          border-top: 0.125rem solid #edeff4;
        }
      }

      .title {
        font-weight: 500;
        font-size: 0.875rem;
      }

      .content {
        color: #586380;
        font-size: 0.875rem;
        min-height: 1rem;
      }

      .btn-danger {
        height: 48px;
      }

      .image {
        .avatar .ant-avatar-string {
          font-size: 3.5rem;
        }

        .btn-plus {
          height: 48px;
          background-color: transparent;
          border-width: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:not(:hover, :active) {
            border-color: #d9d9d9;

            .anticon {
              color: #586380;
            }
          }

          .anticon {
            font-size: 30px;
          }
        }

        .avatar-circle {
          cursor: pointer;
          &:hover {
            border: 2px solid #a8b1ff;
          }
        }
      }
    }
  }
}

@media (min-width: 48.0625rem) {
  .setting {
    max-width: 47.125rem;
    &__content {
      .container-title {
        font-size: 1rem;
      }

      .container-content {
        border-radius: 1rem;
        border: 0.125rem solid #edeff4;

        & > div {
          padding: 1.5rem;
        }

        .title,
        .content {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: 64.0625rem) {
  .setting {
    max-width: 51.8125rem;
    &__title {
      font-size: 2.75rem;
      line-height: 1.2727272727272727;
    }
  }
}
