:root {
  --primary: #4255ff;
  --secondPrimary: #a8b1ff;
  --defaultText: #282e3e;
  --secondText: #586380;
  --defaultBg: #f6f7fb;
  --defaultBorder: #edeff4;
  --light-slate: #d9dde8;
  --darkText: #2e3856;
  --blue-gray: #939bb4;
  --yellow: #ffdc62;
}

body {
  margin: 0;
}

.main-content {
  min-height: calc(100vh - 2rem * 2);
  margin: 2rem auto;
  width: 100%;
  max-width: 81.25rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;

  &:has(.no-padding) {
    padding: 0px;
    margin: 0px;
    max-width: unset;
  }
}

@media screen and (max-width: 1600px) {
  .main-content {
    margin: 0rem auto;
  }
}
