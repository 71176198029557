.finish-review-cards {
  margin: 0 auto 0;
  max-width: 52.5rem;

  .congratulation-image {
    width: 45%;
    display: flex;
    justify-content: flex-end;
  }

  img {
    height: 6.4375rem !important;
    width: 10rem !important;
  }

  .navigate-tab {
    background-color: white;
    border: 0.125rem solid #f6f7fb;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem #2e385614;
    padding: 1rem;
    min-height: 6.25rem;
    height: 100%;
    cursor: pointer;

    &:hover {
      border: 0.125rem solid var(--light-slate);
    }

    .icon .anticon {
      width: 4rem;
      height: 4rem;
    }

    .content {
      flex-grow: 1;
    }

    p {
      &:first-child {
        font-weight: 600;
        font-size: 1rem;
        color: var(--primary);
      }
    }
  }

  .return {
    cursor: pointer;
    font-weight: 600;
    color: var(--secondText);
  }

  .firework {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

@media (max-width: 38.75rem) {
  .finish-review-cards img {
    height: 4.8125rem !important;
    width: 7.5rem !important;
  }
}
