.review-quiz-modal {
  .review-question {
    p {
      margin-top: 0px;

      &:first-child {
        font-size: 1.1em;
        font-weight: 500;
      }

      &:last-child {
        font-weight: 400;
        font-size: 1em;
        margin-bottom: 0px;
      }
    }
  }
}
