.test-result {
  padding: 0rem 2rem;
  position: relative;
  margin: 0 auto 0;
  max-width: 52.5rem;

  h2 {
    color: #586380;
  }

  .navigate-tab {
    background-color: white;
    border: 0.125rem solid #f6f7fb;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem #2e385614;
    padding: 1rem;
    min-height: 6.25rem;
    height: 100%;
    cursor: pointer;

    &:hover {
      border: 0.125rem solid var(--light-slate);
    }

    .icon .anticon {
      width: 4rem;
      height: 4rem;
    }

    .content {
      flex-grow: 1;
    }

    p {
      &:first-child {
        font-weight: 600;
        font-size: 1rem;
        color: var(--primary);
      }
    }
  }

  .firework {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .count-answer {
    & > div {
      margin-bottom: 16px;

      span {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.4;

        &:first-child {
          width: 30px;
        }

        &:last-child {
          margin-left: 3rem;
          border: 0.0625rem solid;
          border-radius: 1.125rem;
          display: flex;
          flex-direction: row;
          height: 1.75rem;
          justify-content: center;
          width: 3rem;
        }
      }

      &:first-child {
        span {
          color: #18ae79;
          &:last-child {
            background-color: #e6fcf4;
          }
        }
      }

      &:last-child {
        span {
          color: #d05700;
          &:last-child {
            background-color: #fff6ef;
          }
        }
      }
    }
  }
}
