.header {
  border-bottom: 0.0625rem solid var(--defaultBorder);
  color: #282e3e;
  height: 64px;
  padding: 0 1rem;
  background-color: white;

  & > div {
    display: inline-flex;
    align-items: center;
  }

  &__left {
    gap: 16px;
    img {
      width: 8rem;
      padding: 0 0.5rem;
    }

    .menu {
      height: 100%;

      .menu-item {
        position: relative;
        cursor: pointer;
        height: 100%;
        margin: 0 calc(0.5 * 1.5rem);
        font-weight: 500;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--defaultText);

        &.popover {
          div {
            height: 100%;
          }
        }

        svg {
          font-size: 12px;
          margin-left: 8px;
        }

        &::after {
          content: " ";
          position: absolute;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          height: 0.25rem;
          right: 0;
          left: 0;
          bottom: 0;
        }

        &:hover,
        &.active {
          &::after {
            background: var(--secondPrimary);
          }
        }
      }
    }
  }

  &__right {
    gap: 16px;

    .ant-avatar {
      cursor: pointer;
    }

    .avatar-circle {
      font-size: 1.25rem;
    }
  }
}
