.add-class-model {
  .btn-new-class {
    font-size: 1rem;
    width: fit-content;
  }

  .quiz-bar {
    border-radius: 1rem;
    background-color: white;
    border: 0.125rem solid #edeff4;
    padding: 1.25rem;
    font-weight: 600;
    font-size: 1.25rem;

    &:hover {
      border-color: #6b7fff;
    }
  }

  .ant-checkbox-group {
    width: 100%;
    display: block;
  }

  .ant-checkbox-wrapper {
    span {
      &:first-child {
        display: none;
      }
      &:last-child {
        flex: 1;
      }
    }

    &:has(.ant-checkbox-checked) {
      .quiz-bar {
        border-color: #6b7fff;
      }
    }
  }
}
