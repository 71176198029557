.share-modal {
  .content {
    font-weight: 500;
    background-color: #f6f7fb;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;

    p {
      color: #586380;
      margin: 0px 0px;
      margin-bottom: 8px;
    }
    span {
      font-size: 1rem;
    }
  }

  .btn-fb-share {
    padding: 1.125rem 1.875rem;
    height: unset;
    font-weight: 500;
    font-size: 1rem;
  }
}
