.dashboard {
  margin-top: 2rem;
  h2 {
    font-weight: 500;
  }

  .banner {
    background-color: #c7f7e6;
    padding: 5.125rem 3rem;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: 700;
      font-size: 2rem;
      line-height: 1.25;
    }

    p {
      min-height: 1.5rem;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.4;
    }

    div {
      &:first-child {
        max-width: 35rem;
        margin: 3rem;
      }
    }

    button {
      padding: 1.125rem 1.875rem;
      min-height: 4rem;
      font-weight: 600;
      font-size: 1rem;
      width: fit-content;
      margin: 0 3rem 3rem;
    }
  }

  .feature-card {
    cursor: pointer;

    .ant-card-meta-description {
      color: #1a1d28;
    }

    &.find {
      background-color: #edefff;
      &:hover {
        background-color: rgba(117, 131, 255, 0.4);
      }
    }

    &.learn {
      background-color: #fff9e3;
      &:hover {
        background-color: rgba(255, 205, 31, 0.4);
      }
    }

    &.test {
      background-color: #fcf0ff;
      &:hover {
        background-color: rgba(227, 114, 255, 0.4);
      }
    }
  }
}

@media (max-width: 48.0625rem) {
  .dashboard .banner {
    height: calc(100vh - 64px);
    max-height: 700px;
    background-image: url("../../../public/images/banner-mobile.avif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}

@media (min-width: 48.0625rem) {
  .dashboard .banner {
    background-image: url("../../../public/images/banner-desktop.avif");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 90rem) {
  .dashboard .banner {
    padding: 5.125rem 5.46875rem;
  }
}

@media (max-width: 38.75rem) {
  .dashboard .banner {
    div {
      &:first-child {
        margin: 1rem 0 1.5rem;
        padding: 0 1rem;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.25;
    }

    p {
      min-height: 1.5rem;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4;
    }

    button {
      margin: 1rem;
    }
  }
}

@media (max-width: 48rem) {
  .dashboard .banner {
    justify-content: flex-start;
    padding: 1.875rem;
  }
}
