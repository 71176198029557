.users {
  padding: 2rem 0px;

  .user-avatar {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50% !important;
  }

  .search-input {
    background-color: white;
    border: 0px;
    border-radius: 0.5rem;
    height: 3rem;
    padding: 1rem;
    color: #586380;
    font-weight: 400;
    font-size: 1rem;
    min-width: 500px;
    outline: none;

    .ant-input-prefix {
      margin-inline-end: 1rem;
      font-size: 20px;
      color: #2e3856;
    }
  }

  .btn-find {
    height: 3rem;
  }

  .pagination {
    margin-top: 32px;
  }

  .quiz-card {
    height: fit-content !important;
  }

  .username {
    font-weight: 600;
    font-size: 1.5rem;
  }
}
