.info-bar {
  margin: 24px 0px;
  .avatar {
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

  .time {
    color: var(--blue-gray);
    font-weight: 400;
    font-size: 0.75rem;
  }

  .username {
    font-weight: 500;
    font-size: 1rem;
    color: var(--darkText);
    cursor: pointer;
  }

  .creator {
    cursor: pointer;
  }
}
