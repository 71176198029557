.review-page {
  .avatar {
    border-radius: 50% !important;
  }

  .review-container {
    padding: 1.5rem;
    border-radius: 3px;
    border: 1px solid #d6d6d7;
    margin: 8px 0px;

    .time {
      color: #9b9b9b;
      font-size: 12px;
    }
  }

  .ant-table {
    background: transparent !important;

    td {
      padding: 0px !important;
    }
  }
}
