.class-card {
  padding: 0.75rem 1.25rem;
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem #00000014;
  overflow: hidden;
  border-bottom: 0.25rem solid #fff0;

  cursor: pointer;

  &:has(.creator-container) {
    height: 170px;
  }

  .name {
    font-weight: 500;
    font-size: 1rem;
  }

  .description {
    font-weight: 500;
    font-size: 0.875rem;
    color: #586380;
  }

  &:hover {
    border-bottom-color: #a8b1ff;
  }
}
