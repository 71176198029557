.my-library-page {
  padding: 1rem 0px;

  .tab-content {
    min-height: 100px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
      padding: 0.75rem 1.25rem;
      background-color: white;
      border-radius: 0.25rem;
      border-bottom: 0.25rem solid #fff0;
      color: #2e3856;
      font-weight: 500;
      font-size: 0.875rem;

      &:hover {
        border-bottom-color: #a8b1ff;
      }

      .quiz-title {
        font-size: 1.25rem;
        font-weight: 600;
      }

      .anticon {
        font-size: 24px;
        color: #d9dde8;
      }

      .border-left {
        border-left: 0.125rem solid #edeff4;
        padding-left: 8px;
      }
    }
  }
}
