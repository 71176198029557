.title-gray-1rem {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3856;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-gray {
  font-weight: 600;
  font-size: 0.75rem;
  align-items: center;
  color: #939bb4;
  max-width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1 {
  font-weight: 700;
  font-size: 2rem;
}

@media (max-width: 38.75rem) {
  h1 {
    font-size: 1.5rem;
  }
}
