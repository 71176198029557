.add-question-from-quiz-model {
  .ant-checkbox-group,
  .ant-collapse {
    width: 100%;

    .ant-collapse-header {
      display: flex;
      align-items: center;
    }

    .ant-checkbox-wrapper {
      width: 100%;
    }
  }
}
