.questions-of-quiz {
  .tabs {
    position: relative;
  }

  .ant-tabs-content-holder {
    min-height: 300px;
  }

  .btn-add-remove,
  .btn-add {
    border-color: #282e3e;
    margin-top: 16px;
  }
}
