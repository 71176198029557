.question-container {
  position: relative;
  padding: 1.25rem 1.5rem;
  background-color: white;

  .ant-col {
    &.answer-col {
      font-size: 1.1em;
      padding-right: 5rem !important;
    }
  }

  .edit-form .question-text {
    padding-right: 5rem !important;
  }

  .feature {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    .feature-item {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      &.active {
        svg {
          color: var(--yellow);
        }
      }

      &:hover {
        background-color: var(--defaultBorder);
      }

      svg {
        width: 18px;
        height: 18px;
        color: var(--secondText);
      }
    }
  }
}

@media (min-width: 768px) {
  .question-container .ant-col {
    &:last-child {
      border-left: 0.125rem solid var(--defaultBg);
    }
  }
}

@media (max-width: 767px) {
  .question-container {
    .ant-col {
      &:last-child {
        padding-right: unset !important;
      }
    }
  }
}
