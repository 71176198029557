.popover-content {
  border-radius: 0.25rem;
  overflow-y: hidden;
  max-height: 520px;
  border-radius: 0.5rem;
  width: 400px;

  .tab-content {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 10px;

    &__item {
      display: block;
      max-width: 100vw;
      padding: 0.5rem;

      .title {
        display: block;
        padding-bottom: 0.5rem;
      }

      .name {
        margin-left: 0.5rem;
      }

      &:hover {
        background: #f6f7fb;
      }
    }
  }

  .footer {
    border-top: 0.0625rem solid #edeff4;
    padding: 1rem 0rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
  }
}
